<template>
  <v-row justify="center" align="center">
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" top>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="1">
          <v-icon>{{ snackbar.icon }}</v-icon>
        </v-col>
        <v-col cols="10">
          <div class="mx-3">
            <p class="snackbar-title font-weight-bold mb-0">
              {{ snackbar.title }}
            </p>
            <p class="snackbar-subtitle mb-0">
              {{ snackbar.text }}
            </p>
          </div>
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="snackbar.visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-col class="py-0" cols="10" sm="10" md="6" lg="6">
      <div class="card text-center shadow-sm p-3 mb-5 bg-white rounded">
        <div class="card-header border-0 py-5">
          <span>
            <img src="media/undraw/new-message.png" height="200" />
          </span>
        </div>
        <div class="card-body" v-if="application">
          <h5 class="card-title font-weight-bold">Permohonan Pembiayaan</h5>
          <p class="card-subtitle mb-2">
            Satu Permohonan Pembiayaan tersedia untuk anda. Sila tekan butang
            <strong>Terima</strong> jika anda ingin teruskan.
          </p>
          <p class="card-text">
            Nama pemohon: {{ application ? application.member.full_name : "" }}
          </p>
          <p class="card-text">
            E-mel: {{ application ? application.member.email : "" }}
          </p>
          <p class="card-text">
            Jumlah dipohon:
            {{
              application
                ? "RM " + application.member.financing_req_amount
                : "RM 0.00"
            }}
          </p>
          <v-btn color="primary" :loading="isLoading" @click="acceptApplication"
            ><span class="font-weight-bold">Terima</span></v-btn
          >
        </div>
        <div class="card-body" v-else>
          <h5 class="card-title">Permohonan Pembiayaan</h5>
          <!-- <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> -->
          <p class="card-text">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </p>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import {
  SHOW_APPLICATION,
  SET_APPLICATION,
  ASSIGN_APPLICATION_BY_EMAIL,
} from "@/core/services/store/application.module";
import { salesOnlineValidation } from "@/core/services/helper.service";
import jwtService from "@/core/services/jwt.service";

export default {
  name: "AcceptApplication",
  components: {},
  data() {
    return {
      isLoading: false,
      snackbar: {
        visible: false,
        icon: "",
        title: "",
        text: "",
        color: "",
      },
    };
  },
  computed: {
    ...mapGetters(["application"]),
  },
  async mounted() {
    if (this.$route.query) {
      // xxconsole.log("Query found!!");
      const lsToken = localStorage.getItem("UUID");

      if (!lsToken) {
        jwtService.saveToken(this.$route.query.t);
      }

      await this.$store
        .dispatch(SHOW_APPLICATION, this.$route.query.app)
        .then((response) => {
          // xxconsole.log(response);
          if (response.success == true) {
            this.$store.commit(SET_APPLICATION, response.data);
          }
        });
        // xxconsole.log(this.application);
    }
  },
  methods: {
    async acceptApplication() {
      if (this.$route.query.app) {
        this.isLoading = true;
        this.$route.query.app; //application_id
        this.$route.query.t; //token

        const lsToken = localStorage.getItem("UUID");

        const output1 = await salesOnlineValidation(lsToken);
        const output2 = await salesOnlineValidation(this.$route.query.t);
        // xxconsole.log(output1);
        // xxconsole.log(output2);

        setTimeout(async () => {
          await this.$store
            .dispatch(ASSIGN_APPLICATION_BY_EMAIL, {
              token: this.$route.query.t,
              application_id: this.$route.query.app,
              from: this.$route.query.from,
              next: this.$route.query.next,
            })
            .then((response) => {
              // xxconsole.log(response);
              if (response.data.success == true) {
                this.isLoading = false;
                this.snackbar.visible = true;
                this.snackbar.icon = "mdi-check-circle";
                this.snackbar.title = "Berjaya";
                this.snackbar.text = response.data.message;
                this.snackbar.color = "primary";

                if (output2.data.data.user.id != output1.data.data.user.id) {
                  localStorage.removeItem("UUID");
                }

                this.$router.push({
                  name: response.data.data.next,
                  params: {
                    id: this.$route.query.app,
                    tabIndex: 1,
                  },
                });
              } else {
                this.isLoading = false;
                this.snackbar.visible = true;
                this.snackbar.icon = "mdi-alert-circle";
                this.snackbar.title = "Ralat";
                this.snackbar.text = response.data.message;
                this.snackbar.color = "red accent-2";
              }
            })
            .catch((err) => {
              this.isLoading = false;
            });
        }, 1000);
      }
    },
  },
};
</script>
<style scoped>
</style>