var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, top: "" },
          model: {
            value: _vm.snackbar.visible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "visible", $$v)
            },
            expression: "snackbar.visible",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.snackbar.icon))])],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("div", { staticClass: "mx-3" }, [
                  _c(
                    "p",
                    { staticClass: "snackbar-title font-weight-bold mb-0" },
                    [_vm._v(" " + _vm._s(_vm.snackbar.title) + " ")]
                  ),
                  _c("p", { staticClass: "snackbar-subtitle mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                  ]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.snackbar.visible = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "py-0",
          attrs: { cols: "10", sm: "10", md: "6", lg: "6" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "card text-center shadow-sm p-3 mb-5 bg-white rounded",
            },
            [
              _c("div", { staticClass: "card-header border-0 py-5" }, [
                _c("span", [
                  _c("img", {
                    attrs: {
                      src: "media/undraw/new-message.png",
                      height: "200",
                    },
                  }),
                ]),
              ]),
              _vm.application
                ? _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h5", { staticClass: "card-title font-weight-bold" }, [
                        _vm._v("Permohonan Pembiayaan"),
                      ]),
                      _c("p", { staticClass: "card-subtitle mb-2" }, [
                        _vm._v(
                          " Satu Permohonan Pembiayaan tersedia untuk anda. Sila tekan butang "
                        ),
                        _c("strong", [_vm._v("Terima")]),
                        _vm._v(" jika anda ingin teruskan. "),
                      ]),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          " Nama pemohon: " +
                            _vm._s(
                              _vm.application
                                ? _vm.application.member.full_name
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          " E-mel: " +
                            _vm._s(
                              _vm.application
                                ? _vm.application.member.email
                                : ""
                            ) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          " Jumlah dipohon: " +
                            _vm._s(
                              _vm.application
                                ? "RM " +
                                    _vm.application.member.financing_req_amount
                                : "RM 0.00"
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", loading: _vm.isLoading },
                          on: { click: _vm.acceptApplication },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Terima"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "card-body" }, [
                    _c("h5", { staticClass: "card-title" }, [
                      _vm._v("Permohonan Pembiayaan"),
                    ]),
                    _c(
                      "p",
                      { staticClass: "card-text" },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    ),
                  ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }